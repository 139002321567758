export const getUrl = () =>
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3002'
    : 'https://magik-api.herokuapp.com'

export const getAppUrl = () =>
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000'
    : process.env.NEXT_PUBLIC_APP_ENV === 'staging'
    ? 'https://withsynch-staging-5681019fe0a6.herokuapp.com'
    : 'https://app.withsynch.com'

export default getUrl
